* {
  margin: 0;
  padding: 0;
}

html, body, #root, .App {
  height: 100%;
}

hr {
  border: none;
  border-top: 1px solid #c7c7c7;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dFlex {
  display: flex;
  flex-direction: row;
}

.fullWidth {
  width: 100%;
}
.m0 {
  margin: 0px;
}
.m4 {
  margin: 4px;
}
.m8 {
  margin: 8px;
}
.m12 {
  margin: 12px;
}
.m16 {
  margin: 16px;
}
.m20 {
  margin: 20px;
}

.adm-toast-mask .adm-toast-main , .wordBreakNormal{
  word-break: normal;
}